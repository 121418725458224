@import '../../../common/SASS/palette';
@import '../../../common/SASS/mixins';

  /****************************INVITE MODAL************************/
  .invite-modal {
    @include onboard-form;
    @include themify($themes) {
      background-color: themed('bg');
    }
    margin: 0;
    width: 100%;
    overflow: auto;

    position: relative;
    display: flex;
    width: 500px;
    flex-direction: column;
    border-radius: 7px;
    background-color: $black;
    padding: 40px;
    @include themify($themes) {
      background-color: themed('bg2');
    }

    .close-icon-div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 8%;
      position: absolute;
      top: 10px;
      right: 40px;
      background: none;

      div.close-icon {
        height: 35px;
        width: 35px;
        //border-radius: 50%;
        //border: 1px solid $field-stroke;        
        display: flex;
        align-items: center;
        justify-content: center;
        background: none;


        margin: 3% 3%;
        font-family: 'Helvetica';
        font-size: 12px;

        cursor: pointer;
        // @include themify($themes) {
        //   background-color: themed('bg2');
        // }
      }
    }

    form {
      @include onboard-form;
      

      @include themify($themes) {
        background-color: white;
      }
      margin: 0;
      width: 100%;

      label {
        user-select: none;
        text-align: left;
        margin-top: 10px;
        margin-bottom: 5px;
        font-size: 15px;
        line-height: 21px;
      }

      div.colab-input-wrapper {
        display: flex;
        flex-grow: 3;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: space-around;
        padding: 0px;
        max-width: 100%;
        height: 36px;
        height: auto;
        font-size: 12px;
        margin-bottom: 20px;
        border: 1px solid $field-stroke;        
        @include themify($themes) {
          background-color: themed('bg2');
        }

        div.invite-chip {
          display: flex;
          flex-wrap: nowrap;
          justify-content: space-between;
          align-items: center;
          background-color: $white;
          border-radius: 3px;
          margin-top: 2px;
          margin-bottom: 2px;
          margin-right: 10px;
          height: 90%;
          color: $black;
          width: auto;

          .remove-chip {
            margin-left: 10px;
          }
        }

        input {
          flex-grow: 3;         
          height: 35px;
          font-size: 12px;
          margin-top: 0px;          
          border: 1px solid $field-stroke;
          
          @include themify($themes) {
            border: 1px solid $shadow-grey;
            background-color: themed('bg2');
          }
        }
      }
    } //end form

    label {
      text-align: left;
      margin-top: 10px;
      margin-bottom: 5px;
      font-size: 15px;
      line-height: 21px;
    }

    .collab-view {
      font-size: 12px;
      margin-bottom: 20px;
      height: 240px;
      overflow: auto;
      @include themify($themes) {
        background-color: themed('bg2');
      }
    }

    .invite-modal-bottom-div {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-around;

      .share-icon-div {
        display: flex;
        align-items: flex-end;
        width: 8%;

        div.share-icon {
          width: 80%;
          border-radius: 100%;
          border: 1px solid $field-stroke;          
          display: flex;
          align-items: center;
          justify-content: center;
          height: 32px;
          margin: 2% 0%;
          cursor: pointer;
          @include themify($themes) {
            background-color: themed('bg2');
          }
        }
      }

      .share-link-div {
        @include onboard-form;
        @include themify($themes) {
          background-color: white;
        }

        margin: 0;
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 70%;

        .label {
          margin-bottom: 0;
          font-size: 12px;
          line-height: 10px;
        }

        input {
          font-size: 12px;
          padding: 7px;         
          border: 1px solid $field-stroke;
          @include themify($themes) {
            border: 1px solid $shadow-grey;
            background-color: themed('bg2');
          }
        }
      }

      .add-members-btn-div {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        width: 30%;

        .submit-button {
          @include primary-btn;
          font-size: 10px;
          padding: 0 0;
          width: 80%;
          height: 40%;          
        }
      }
    } //end invite-modal-bottom-div
  } //end invite-modal
